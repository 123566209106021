






































import { popoStore } from '@/stores/popo-store'
import { when } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'decorate-button': () => import('@/modules/popo/components/decorate-button.vue'),
    'avatar-list': () => import('@/modules/popo/components/avatar-closets/avatar-list.vue'),
  },
})
export default class extends Vue {
  popoStore = popoStore
}
